<template>
  <div class=" container-fluid container-fixed-lg">
    <div class="card card-transparent">
      <div class="card-header d-flex justify-content-between">
        <div class="page-title card-title">
          宿泊料金の登録
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <ValidationObserver ref="form_hotel_fee_rule" v-slot="{ passes, valid, validated }">
              <form novalidate autocomplete="off" @submit.prevent="passes(saveEntry)">
                <div data-pages="card" class="card card-default">
                  <div class="card-body">
                    <HeaderNotice ref="date_range_error_create">
                      <template v-slot:error>
                        <div>既に登録された期間があります。(
                          <router-link
                            :to="{
                        name: $consts.ROUTES.HOTEL.HOTEL_FEE_RULE_LIST,
                        query: {
                          'filters.start_date.in_date_range_start': entry.start_date,
                          'filters.end_date.in_date_range_end': entry.end_date,
                          'filters.room_type_id.equal': entry.room_type_id,
                        }
                      }" target="_blank">こちら
                          </router-link>
                          をご確認ください）
                        </div>
                      </template>
                    </HeaderNotice>
                    <div class="row">
                      <div class="col-md-6">
                        <AppInput name="name" :label="$t('hotel.plan name')" type="string" rules="required"
                                  v-model="entry.name"/>
                        <AppDatePicker name="start_date" :label="$t('common.start_date')"
                                       rules="required"
                                       v-model="entry.start_date"/>
                        <AppDatePicker name="end_date" :label="$t('common.end_date')"
                                       rules="required|after_eq_date:@start_date"
                                       v-model="entry.end_date"/>
                        <AppSelect :options="{placeholder: $t('common.hotel_room_type')}"
                                   name="hotel_room_type"
                                   :label="$t('common.hotel_room_type')" rules="required"
                                   :options-data="hotelRoomTypes"
                                   v-model="entry.room_type_id">
                        </AppSelect>
                      </div>
                    </div>
                    <div class="app-table-container">
                      <div class="dataTables_wrapper no-footer bg-white">
                        <button type="button" @click="setNewDetail"
                                :class="['btn btn-cons inline pull-right btn-modal-submit', 'btn-create']">
                          追加
                        </button>
                        <div class="table-responsive sm-m-b-15">
                          <table :class="['table dataTable app-table no-footer']" style="border: 1px solid #a5a5a5">
                            <thead>
                            <th class="v-align-middle text-center min-w-80" style="width: 80px">１室の人数 <span
                              class="text-danger"> *</span></th>
                            <th class="v-align-middle text-center min-w-80" style="width: 120px">宿泊料金<br>（平日）<span
                              class="text-danger"> *</span></th>
                            <th class="v-align-middle text-center min-w-80" style="width: 140px">宿泊料金<br>（休前日）<span
                              class="text-danger"> *</span>
                            </th>
                            <th class="v-align-middle text-center min-w-80" style="width: 140px">宿泊料金<br>（特日）<span
                              class="text-danger"> *</span></th>
                            <th class="v-align-middle text-center min-w-130">ドライバー様宿泊料金<br> （平日）<span class="text-danger"> *</span>
                            </th>
                            <th class="v-align-middle text-center min-w-130">ドライバー様宿泊料金<br> （休前日）<span class="text-danger"> *</span>
                            </th>
                            <th class="v-align-middle text-center min-w-130">ドライバー様宿泊料金<br> （特日）<span class="text-danger"> *</span>
                            </th>
                            <th class="v-align-middle text-center" style="width: 50px">削除</th>
                            </thead>
                            <tbody>
                            <tr v-for="(detail, key) in details">
                              <td>
                                <FeeRuleInput :name="key + 'people'" label="" col-input="12"
                                              type="number-length:1"
                                              boundary-class="m-b-0 b-b-0 p-0"
                                              rules="required|min_value:1"
                                              :blur="validatePeople"
                                              v-model="details[key].people"/>
                              </td>
                              <td>
                                <FeeRuleInput :name="key + 'fee_normal'" label="" col-input="12" type="int"
                                              boundary-class="m-b-0 b-b-0 p-0"
                                              rules="required"
                                              v-model="details[key].fee_normal"/>
                              </td>
                              <td>
                                <FeeRuleInput :name="key + 'fee_holiday'" label="" col-input="12" type="int"
                                              boundary-class="m-b-0 b-b-0 p-0"
                                              rules="required"
                                              v-model="details[key].fee_holiday"/>
                              </td>
                              <td>
                                <FeeRuleInput :name="key + 'fee_special'" label="" col-input="12" type="int"
                                              boundary-class="m-b-0 b-b-0 p-0"
                                              rules="required"
                                              v-model="details[key].fee_special"/>
                              </td>
                              <td>
                                <FeeRuleInput :name="key + 'fee_driver_normal'" label="" col-input="12" type="int"
                                              boundary-class="m-b-0 b-b-0 p-0"
                                              rules="required"
                                              v-model="details[key].fee_driver_normal"/>
                              </td>
                              <td>
                                <FeeRuleInput :name="key + 'fee_driver_holiday'" label="" col-input="12" type="int"
                                              boundary-class="m-b-0 b-b-0 p-0"
                                              rules="required"
                                              v-model="details[key].fee_driver_holiday"/>
                              </td>
                              <td>
                                <FeeRuleInput :name="key + 'fee_driver_special'" label="" col-input="12" type="int"
                                              boundary-class="m-b-0 b-b-0 p-0"
                                              rules="required"
                                              v-model="details[key].fee_driver_special"/>
                              </td>
                              <td class="v-align-middle text-center">
                                <div v-if="Object.keys(details).length > 1" @click="removeDetail(key)"
                                     class="btn-tool delete">
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <button :disabled="!valid && validated" type="submit"
                            :class="['btn btn-cons inline pull-right btn-modal-submit', 'btn-complete']">
                      {{ $t('common.save') }}
                    </button>
                    <button type="button"
                            @click="$router.push({name: $consts.ROUTES.HOTEL.HOTEL_FEE_RULE_LIST, params: {id: hotel.id}})"
                            :class="['btn btn-cons inline pull-right btn-modal-submit', 'btn-default']">
                      キャンセル
                    </button>
                  </div>
                </div>

              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {v4} from 'uuid';
import FeeRuleInput from "@components/hotel/fee_rules/FeeRuleInput";

export default {
  components: {
    FeeRuleInput: FeeRuleInput
  },
  data() {
    return {
      start_date: null,
      end_date: null,
      entry: {},
      details: {},
      hotel_room_type_id: null,
      hotelRoomTypes: [],
    }
  },
  mounted() {
    this.setNewDetail();
    this.$request.get(this.$consts.API.HOTEL.HOTEL_SHOW, null, {
      noLoadingEffect: true,
      handleNotFound: true
    }).then(res => {
      if (!res.hasErrors()) {
        this.hotel = res.data.data
        this.$store.commit('setting/updateBreadCrumb', {hotel: this.hotel.name})
        this.$request.get(this.$consts.API.HOTEL.HOTEL_ROOM_TYPE_LIST, null, {noLoadingEffect: true}).then(res => {
          if (!res.hasErrors()) {
            this.hotelRoomTypes = res.data.data
            // this.setOptionFilter(this.tools, 'hotel_room_type_id', 'equal', this.hotelRoomTypes);
          }
        })
      }
    })
  },
  watch: {
    'details': {
      handler: function () {
        this.validatePeople();
      },
      deep: true
    }
  },
  methods: {
    setNewDetail() {
      let key = v4();
      this.details[key] = {
        people: null,
        fee_normal: null,
        fee_special: null,
        fee_holiday: null,
        fee_driver_normal: null,
        fee_driver_special: null,
        fee_driver_holiday: null,
      };
      this.details = _.clone(this.details);
    },
    removeDetail(key) {
      delete this.details[key];
      this.details = _.clone(this.details)
    },
    async saveEntry() {
      if (!this.validatePeople()) {
        return false;
      }
      this.$refs.date_range_error_create.clear();
      const {start_date, end_date, room_type_id, name} = this.entry
      const res = await this.$request.post(this.$consts.API.HOTEL.HOTEL_FEE_RULE_SAVE, {
        start_date, end_date, room_type_id, fee_rule_details: this.details, name, hotel_id: this.hotel.id
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg create ok'))
        this.$router.go(-1)
      } else if (res.data?.date_error) {
        this.$refs.date_range_error_create.setError();
      } else {
        if (res.data?.errors) {
          this.$refs.form_hotel_fee_rule.setErrors(res.data.errors);
        }
        this.$appNotice.error(res.data?.error_msg)
      }
    },
    validatePeople() {
      var keyErrors = []
      var cloneDetails = _.cloneDeep(this.details);
      var details = {};
      var validateResult = true;
      _.forEach(cloneDetails, (detail, index) => {
        if (detail.people > 0) {
          details[index] = detail
        }
      })
      _.forEach(details, (detail, key) => {
        var dup = 0;
        _.forEach(details, subDetail => {
          if (detail.people === subDetail.people) {
            dup++;
          }
        })
        if (dup > 1) {
          keyErrors.push(key)
          setTimeout(() => {
            this.$refs.form_hotel_fee_rule.setErrors({
              [key + 'people']: 'この人数は設定済みです。'
            });
          }, 0)
          validateResult = false;
        }
      })
      _.forEach(details, (detail, key) => {
        if (!keyErrors.includes(key)) {
          this.$refs.form_hotel_fee_rule.setErrors({
            [key + 'people']: null
          });
        }
      });
      return validateResult;
    }
  }
}
</script>
<style scoped lang="scss">
</style>
