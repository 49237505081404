import { render, staticRenderFns } from "./HotelFeeRuleCreate.vue?vue&type=template&id=6feb7bf2&scoped=true&"
import script from "./HotelFeeRuleCreate.vue?vue&type=script&lang=js&"
export * from "./HotelFeeRuleCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6feb7bf2",
  null
  
)

export default component.exports